import React from "react";
import styled from "styled-components";
import { darkBlue, white } from "../../base_components/colors";

const DotStyle = styled.span`
  padding: 6px;
  margin: 10px;
  cursor: pointer;
  border-radius: 50%;
  border: ${props => (props.active ? `none` : `1px solid ${darkBlue}`)};
  background: ${props => (props.active ? `${darkBlue}` : `${white}`)};
`;

const Dot = ({ id, active, onClick }) => {
  return <DotStyle active={active} onClick={() => onClick(id)} />;
};

const DotsStyle = styled.span`
  position: absolute;
  bottom: 25px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Dots = ({ slides, activeIndex, onClick }) => (
  <DotsStyle>
    {slides.map((slide, i) => (
      <Dot key={i} id={i} active={activeIndex === i} onClick={onClick} />
    ))}
  </DotsStyle>
);

export default Dots;
