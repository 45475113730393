import styled from "styled-components";

import {
  SectionContainer,
} from "../base_components/layout";


import { darkBlue } from "../base_components/colors";

export const SliderContainer = styled(SectionContainer)`
  position: relative;
  overflow: hidden;
  color: ${darkBlue};
  display: none;
`;

