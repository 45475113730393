import styled from "styled-components";

import { Col, Row } from "../base_components/layout";
import { sizes } from "../base_components/screenSizes";
import { mediumBlue, white } from "../base_components/colors";
import { SPsh1 } from "../base_components/typography";

export const DetailStyle = styled(SPsh1)`
  color: ${white};
`;

export const DetailContainer = styled.div`
  text-align: center;
  padding: 0px 0px 0px 40px;
  @media (max-width: ${sizes.medium}px) {
    margin-top: 50px;
  }
`;

export const HeroImg = styled.img`
  width: 65%;
  border-radius: 10px;
  height: intrinsic;
`;

export const HeroContainer = styled.div`
  background-color: ${mediumBlue};
  color: ${white};
  overflow: hidden;
  position: relative;
    height: fit-content;
    min-height: 100vh;
  display: flex;
  z-index: 1;
  justify-content: center;

  @media (max-width: ${sizes.medium}px) {
    height: auto;
    min-height: inherit;
  }
`;

export const HeroCol = styled(Col)`
  position: relative;

  @media (max-width: 768px) {
    flex-basis: 100%;
  }
`;

export const HeroImgCol = styled(HeroCol)`
  display: flex;
  justify-content: center;
`;

export const HeroRow = styled(Row)`
  align-items: center;
  margin-top: 60px;
  padding-top: 50px;
  padding-bottom: 50px;
  width: 100%;
`;
