import styled from "styled-components";
import { orange } from "../../base_components/colors";
import { sizes } from "../../base_components/screenSizes";

export const SliderStyle = styled.div`
  position: relative;
  height: auto;
  width: 100vw;
  overflow: hidden;

  margin-left: -80px;

  @media (max-width: ${sizes.large}px) {
    margin-left: -20px;
  }

  @media (max-width: ${sizes.medium}px) {
    margin-bottom: 20px;
  }
`;

export const SliderContentStyle = styled.div`
  transform: translateX(${props => props.translate}px);
  transition: transform ease-out ${props => props.transition}s;
  height: auto;
  width: ${props => props.width}px;
  display: flex;
`;

export const Line = styled.div`
  position: absolute;
  width: ${props => props.width}px;

  border: 3px solid ${orange};
`;
