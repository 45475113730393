import React from "react";
import { StaticQuery, graphql } from "gatsby";

import BackgroundPattern from "../base_components/background";
import { SectionContainer } from "../base_components/layout";
import { RichText } from "prismic-reactjs-custom";

import {
  EventDetailContainer,
  EventImgContainer,
  EventCol,
  EventRow,
  EventImg,
  DateStyle,
  TitleStyle,
  DetailStyle,
} from "../about/timeline_events/timeline_event_styles";

const Step = props => {
  const { info, isRightPhoto } = props;
  const content = info.step_content;
  const titleInfo = info.step_title[0].text.split(":");
  const imgSrc = info.step_image.url;
  const imgAlt = info.step_image.alt;
  const stepNum = titleInfo[0] + ":";
  const title = titleInfo[1];

  return (
    <EventRow isRightPhoto={isRightPhoto}>
      <EventCol size={1} breakSize={"medium"}>
        <EventImgContainer>
          <EventImg src={imgSrc} alt={imgAlt} />
        </EventImgContainer>
      </EventCol>

      <EventCol size={1} breakSize={"medium"}>
        <EventDetailContainer>
          <DateStyle>{stepNum}</DateStyle>
          <TitleStyle>{title}</TitleStyle>
          <RichText richText={content} paragraph={DetailStyle} />
        </EventDetailContainer>
      </EventCol>
    </EventRow>
  );
};

const Steps = props => {
  const { data, id } = props;
  const stepList = data.prismic.allHomepages.edges[0].node.steps;
  return (
    <SectionContainer
      style={{ position: "relative", overflow: "hidden" }}
      id={id}
      role="region" aria-labelledby={id}
    >
      <BackgroundPattern
        strokeColor={"orange"}
        fillColor={"yellow"}
        left={-100}
        top={150}
        zindex={-1}
      />

      <BackgroundPattern
        strokeColor={"orange"}
        fillColor={"yellow"}
        bottom={200}
        right={-100}
        zindex={-1}
      />

      {stepList.map((key, index) => {
        const isRight = index % 2 !== 0;
        const info = stepList[index];
        return <Step info={info} isRightPhoto={isRight} />;
      })}
    </SectionContainer>
  );
};

export default props => (
  <StaticQuery
    query={graphql`
      query MyQuery {
        prismic {
          allHomepages {
            edges {
              node {
                _linkType
                steps {
                  step_content
                  step_image
                  step_title
                }
              }
            }
          }
        }
      }
    `}
    render={data => <Steps data={data} {...props} />}
  />
);
