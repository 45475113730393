import React from 'react';
import styled from "styled-components";

import { darkBlue, white } from "../../base_components/colors";
import { sizes } from "../../base_components/screenSizes";

import LeftArrow from '../../../images/slider/left-arrow.svg';
import RightArrow from '../../../images/slider/right-arrow.svg';

const ArrowStyle = styled.div`
    display: flex;
      position: absolute;
      top: 50%;
      border: 2px solid ${darkBlue};
      transform: translateY(-50%);
      ${props => props.direction === 'right' ? `right: 25px` : `left: 25px`};
      height: 25px;
      width: 25px;
      justify-content: center;
      background: ${white};
      border-radius: 50%;
      cursor: pointer;
      align-items: center;
      transition: transform ease-in 0.1s;
      z-index: 10;

      svg {
          width: 8px;
          stroke: ${darkBlue};
      }

      :hover {
          border: 2px solid ${white};
          stroke: ${white};
            background: ${darkBlue};

        svg {

          stroke: ${white};
        }
      }

            @media(max-width: ${sizes.small}px) {
        display: none;

    }
      
`;



const Arrow = (props) => {
    const { direction, handleClick } = props;
    return(
        <ArrowStyle direction={direction} onClick={() => handleClick()}>
        {direction === 'right' ? <RightArrow/> : <LeftArrow/>}
        </ArrowStyle>
    );
}

export default Arrow;