import React, { Component } from "react";
import { graphql } from "gatsby";

import SEO from "../components/seo";

import { ParallaxProvider } from "react-scroll-parallax";

import { PageContainer } from "../components/base_components/layout";

import Header from "../components/navbar/header";
import Hero from "../components/index/hero";
import WhatIs from "../components/index/what_is";

import Step from "../components/index/step";
import Features from "../components/index/features";
import Takeback from "../components/index/takeback";
import Carousel from "../components/index/carousel";
import Stayconnected from "../components/footer/stayconnected";

class IndexPage extends Component {
  render() {
    const { data } = this.props;

    const welcome = data.prismic.allHomepages.edges[0].node.welcome[0].text;
    const heroImg = data.prismic.allHomepages.edges[0].node.hero_image.url;
    const heroAlt = data.prismic.allHomepages.edges[0].node.hero_image.alt;

    return (
  
      <ParallaxProvider>
        <SEO title="Home" />
        <Header />
        <PageContainer>
          <Hero title={welcome} heroImg={heroImg} alt={heroAlt}/>
          <WhatIs />
          <Step id="how_to_use" />
          <Features id="benefits" />

          <Takeback title={"What will you take back with SmartyPill?"} />
          <Carousel />
        </PageContainer>
        <Stayconnected />
      </ParallaxProvider>

    );
  }
}

export const query = graphql`
  {
    prismic {
      allHomepages {
        edges {
          node {
            hero_image
            welcome
          }
        }
        pageInfo {
          startCursor
          endCursor
        }
      }
    }
  }
`;

export default IndexPage;
