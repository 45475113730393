import React, { useState } from "react";
import { StaticQuery, graphql } from "gatsby";

import Fade from "react-reveal/Fade";
import { Collapse } from "react-collapse";

import { RichText } from "prismic-reactjs-custom";

import { Grid } from "../base_components/layout";

import {
  CollapsePlusMinus,
  CollapseContent,
  TakebackCol,
  TakebackRow,
  TakebackHeader,
  TakebackContentContainer,
  TakebackCollapseContentContainer,
  TakeBackTitleContainer,
  TakebackSPh2,
  TakebackSPbody1,
  TakeBackBackground,
  TitleStyle,
  TakebackFiller,
} from "./takeback_styles";

const FadeReveal = props => {
  const [show, setShow] = useState(false);
  const showMsg = show ? "-" : "+";

  return (
    <TakebackCollapseContentContainer>
      <CollapsePlusMinus>
        <TakebackSPh2 onClick={() => setShow(!show)}>{showMsg}</TakebackSPh2>
      </CollapsePlusMinus>
      <CollapseContent>
        <TakebackSPh2 isUnderline onClick={() => setShow(!show)}>
          {props.takebackItemTitle}
        </TakebackSPh2>

        <Collapse isOpened={show}>
          <Fade left when={show}>
            <TakebackSPbody1>{props.takebackItemContent}</TakebackSPbody1>
          </Fade>
        </Collapse>
      </CollapseContent>
    </TakebackCollapseContentContainer>
  );
};

const TakeBack = props => {
  const { data } = props;
  const title = data.prismic.allHomepages.edges[0].node.takeback_title[0].text;
  const callout =
    data.prismic.allHomepages.edges[0].node.takeback_callout[0].text;
  const items = data.prismic.allHomepages.edges[0].node.takeback_items;

  const lastItemBody = items[items.length - 1].takeback_item_content[0].text;
  return (
    <TakeBackBackground>
      <TakeBackTitleContainer>
        <TitleStyle>{title}</TitleStyle>
      </TakeBackTitleContainer>

      <Grid isNotHidden>
        <TakebackRow>
          <TakebackCol size={1} breakSize="medium">
            <TakebackHeader>{callout}</TakebackHeader>
            <TakebackFiller>
              <TakebackSPbody1 style={{ visibility: "hidden" }}>
                {lastItemBody}
              </TakebackSPbody1>
            </TakebackFiller>
          </TakebackCol>

          <TakebackCol size={1} breakSize="medium">
            {items.map(item => {
              const takebackItemTitle = item.takeback_item_title[0].text;
              const takebackItemContent = item.takeback_item_content;
              return (
                <TakebackContentContainer>
                  <Fade right>
                    <TakebackSPh2 isUnderline>{takebackItemTitle}</TakebackSPh2>
                    <RichText
                      richText={takebackItemContent}
                      paragraph={TakebackSPbody1}
                    />
                  </Fade>
                </TakebackContentContainer>
              );
            })}
            {items.map(item => {
              const takebackItemTitle = item.takeback_item_title[0].text;
              const takebackItemContent = item.takeback_item_content[0].text;
              return (
                <FadeReveal
                  takebackItemTitle={takebackItemTitle}
                  takebackItemContent={takebackItemContent}
                />
              );
            })}
          </TakebackCol>
        </TakebackRow>
      </Grid>
    </TakeBackBackground>
  );
};

export default props => (
  <StaticQuery
    query={graphql`
      query {
        prismic {
          allHomepages {
            edges {
              node {
                takeback_title
                takeback_callout
                takeback_items {
                  takeback_item_content
                  takeback_item_title
                }
              }
            }
          }
        }
      }
    `}
    render={data => <TakeBack data={data} {...props} />}
  />
);
