import React, { useState, useRef, useEffect } from "react";

import { SliderStyle, SliderContentStyle } from "./slider_styles";
import Slide from "./slide";
import Arrow from "./arrow";
import Dots from "./dots";

const Slider = props => {

  const resizeRef = useRef();
  const autoPlayRef = useRef();

  useEffect(() => {
    resizeRef.current = handleResize;
    autoPlayRef.current = nextSlide;
  });

  useEffect(() => {
    const resize = () => {
      resizeRef.current();
    };

    const play = () => {
      autoPlayRef.current()
    }

    const interval = setInterval(play, props.autoPlay * 1000);




    setTimeout(function(){ 
      handleResize();
      }, 500);


    const onResize = window.addEventListener("resize", resize);

    return () => {
      clearInterval(interval)
      window.removeEventListener("resize", onResize);
    };
  }, []);

  const handleResize = () => {
    setState({ ...state, width: 0 });
    let newWidth = getWidth();
    setState({ ...state, width: newWidth, translate: state.activeIndex * getWidth() });
  };



  const getWidth = () => {
    if (typeof window !== `undefined`) {
      return window.innerWidth;
    }
    return 1440;
  };

  const onChange = id => {

    setState({
      ...state,
      activeIndex: id,
      translate: (id) * getWidth(),
    });
  };

  const [state, setState] = useState({
    activeIndex: 0,
    translate: 0,
    transition: 0.45,
    width: getWidth(),
  });


  const { transition, activeIndex, width, translate } = state;

  const nextSlide = () => {
    if (activeIndex === props.slides.length - 1) {
      return setState({
        ...state,
        activeIndex: 0,
        translate: 0,
      });
    }
    setState({
      ...state,
      activeIndex: activeIndex + 1,
      translate: (activeIndex + 1) * getWidth(),
    });
  };

  const prevSlide = () => {
    if (activeIndex === 0) {
      return setState({
        ...state,
        activeIndex: props.slides.length - 1,
        translate: (props.slides.length - 1) * getWidth(),
      });
    }
    setState({
      ...state,
      activeIndex: activeIndex - 1,
      translate: (activeIndex - 1) * getWidth()
    });
  };



  return (
    <div>
    <Arrow direction="left" handleClick={prevSlide} />
    <Arrow direction="right" handleClick={nextSlide} />
    <SliderStyle width={width}>
      <SliderContentStyle
        translate={translate * -1}
        transition={transition}
        width={width * props.slides.length}
      >
        {props.slides.map((item, index) => {
          return (
            <Slide id={index} content={item} title={props.title}/>
          );
        })}
      </SliderContentStyle>


    </SliderStyle>
    <Dots slides={props.slides} activeIndex={activeIndex} onClick={onChange}/>
    </div>
  );
};

export default Slider;
