import styled from "styled-components";
import { SPh1, SPbody1 } from "../base_components/typography";
import { darkBlue, white } from "../base_components/colors";
import { sizes } from "../base_components/screenSizes";

import { SectionContainer } from "../base_components/layout";

export const FeatureHeader = styled(SPh1)`
    color: ${white};
    margin-bottom: 20px;
`;

export const FeatureContainer = styled(SectionContainer)`
    background: ${darkBlue};
    text-align: center;
    overflow: hidden;
    padding-left: 0px !important;
    padding-right: 0px !important;
`;

export const FeatureGrid = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
`;

export const FeatureSingleContainer = styled.div`
  flex-basis: 18%;
  -ms-flex: auto;
  width: 259px;
  position: relative;
  box-sizing: border-box;
  margin-top: 30px;
  @media(max-width: ${sizes.small}px) {
      flex-basis: 48%;
      margin-top: 10px;
  }
`;

export const FeatureImg = styled.img`
  width: 90px;
  @media(max-width: ${sizes.medium}px) {
      width: 60px;
  }
    @media(max-width: ${sizes.small}px) {
      width: 40px;
  }
`;

export const FeatureText = styled(SPbody1)`
    color: ${white};
`;