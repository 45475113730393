import styled from "styled-components";

import { CenteredContainer2 } from "../base_components/layout";
import { sizes } from "../base_components/screenSizes";
import { Col, Row, SectionContainer } from "../base_components/layout";
import { lightBlue, yellow, grey } from "../base_components/colors";

import { SPh1, SPh2, SPbody1 } from "../base_components/typography";

export const TakebackFiller = styled.div`
  position: absolute;
  bottom: 0;
  background: ${lightBlue};

  @media (max-width: ${sizes.medium}px) {
    display: none;
  }
`;

export const TakeBackBackground = styled(SectionContainer)`
  background: ${lightBlue};
`;

export const CollapsePlusMinus = styled.div`
  padding: 8px;
`;

export const CollapseContent = styled.div`
  padding: 8px;
`;

export const TakebackCol = styled(Col)`
  position: relative;
  @media (max-width: ${sizes.medium}px) {
    padding: 0 20px;
  }
`;

export const TakebackRow = styled(Row)`
  scroll-snap-type: y mandatory;
  width: 90%;
`;

export const TakeBackTitleContainer = styled.div`
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  transition: margin 700ms;
`;

export const TakebackSPh2 = styled(SPh2)`
  color: ${grey};
  text-decoration: ${props => (props.isUnderline ? "underline" : "initial")};
  }
  text-decoration-color: ${yellow};
`;

export const TakebackSPbody1 = styled(SPbody1)`
  color: ${grey};
  margin-top: 20px;
`;

export const TitleStyle = styled(SPh1)`
  color: ${grey};
`;

export const TakebackHeader = styled(SPh2)`
  color: ${grey};
  text-align: center;
  @media (min-width: ${sizes.medium}px) {
    top: 2em;
    position: sticky;
    padding: 20px;
    display: inherit;
  }
  @media (max-width: ${sizes.medium}px) {
    display: none;
  }
`;

export const TakebackContentContainer = styled.div`
  scroll-snap-align: start;
  padding: 20px;

  @media (max-width: ${sizes.medium}px) {
    display: none;
  }
`;

export const TakebackCollapseContentContainer = styled.div`
  display: flex;
  @media (min-width: ${sizes.medium}px) {
    display: none;
  }
`;

const largeMarginSize = 100;
const xlMarginSize = 200;

export const StepCol = styled(Col)`
  position: relative;
`;

export const StepRow = styled(Row)`
  @media (max-width: ${sizes.large}px) {
    flex-direction: ${props =>
      props.isRightPhoto ? "column-reverse" : "initial"};
  }
`;

export const StepContainer = styled.div`
  margin: 25px 0 25px 0;
  @media (max-width: ${sizes.xl}px) {
    margin: 25px 100px 25px 100px;
  }

  @media (max-width: ${sizes.large}px) {
    margin: 25px 50px 25px 50px;
  }

  @media (max-width: ${sizes.xs}px) {
    margin: 16px 8px 16px 8px;
  }
`;

export const StepPhoto = styled.div`
  height: 300px;
  background: grey;
  position: absolute;
  width: 100%;
  transform: translateY(-50%);
  top: 50%;
  z-index: 1;
  @media (min-width: ${sizes.xl}px) {
    right: ${props => (props.isRightPhoto ? "initial" : `-${xlMarginSize}px`)};
    left: ${props => (props.isRightPhoto ? `-${xlMarginSize}px` : "initial")};
  }

  @media (min-width: ${sizes.large}px) {
    right: ${props =>
      props.isRightPhoto ? "initial" : `-${largeMarginSize}px`};
    left: ${props =>
      props.isRightPhoto ? `-${largeMarginSize}px` : "initial"};
  }

  @media (max-width: ${sizes.large}px) {
    transform: none;
    top: auto;
    position: relative;
    width: 100%;
    right: 0;
  }
`;

export const StepText = styled(CenteredContainer2)`
  height: 400px;
  background: lightgrey;

  @media (min-width: ${sizes.xl}px) {
    width: calc(100% - ${xlMarginSize}px);
    margin-left: ${props => (props.isRightPhoto ? `${xlMarginSize}px` : 0)};
  }

  @media (min-width: ${sizes.large}px) {
    width: calc(100% - ${largeMarginSize}px);
    margin-left: ${props => (props.isRightPhoto ? `${largeMarginSize}px` : 0)};
  }

  @media (max-width: ${sizes.xs}px) {
    width: 100%;
  }
`;
