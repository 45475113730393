import React from "react";
import { StaticQuery, graphql } from "gatsby";
import BackgroundPattern from "../base_components/background";

import {
  FeatureContainer,
  FeatureHeader,
  FeatureSingleContainer,
  FeatureImg,
  FeatureText,
  FeatureGrid,
} from "./features_styles";

const SingleFeature = props => {
  const { img, name } = props;
  const imgUrl = img.url;
  const imgAlt = img.alt;
  return (
    <FeatureSingleContainer>
      <FeatureImg src={imgUrl} alt={imgAlt} />
      <FeatureText>{name}</FeatureText>
    </FeatureSingleContainer>
  );
};

const Features = props => {
  const { data, id } = props;
  const featureTitle =
    data.prismic.allHomepages.edges[0].node.feature_title[0].text;
  const features = data.prismic.allHomepages.edges[0].node.features;
  return (
    <FeatureContainer id={id} role="region" aria-labelledby={id}>
      <BackgroundPattern
        strokeColor={"pillBackgroundGreen"}
        fillColor={"pillBackgroundGreen"}
        left={-100}
        top={-100}
        zindex={-1}
      />
      <BackgroundPattern
        strokeColor={"pillBackgroundGreen"}
        fillColor={"pillBackgroundGreen"}
        right={-100}
        bottom={-100}
        zindex={-1}
      />

      <FeatureHeader>{featureTitle}</FeatureHeader>
      <FeatureGrid>
        {features.map(item => {
          const name = item.feature_name[0].text;
          return <SingleFeature img={item.feature_img} name={name} />;
        })}
      </FeatureGrid>
    </FeatureContainer>
  );
};

export default props => (
  <StaticQuery
    query={graphql`
      query {
        prismic {
          allHomepages {
            edges {
              node {
                feature_title
                features {
                  feature_img
                  feature_name
                }
              }
            }
          }
        }
      }
    `}
    render={data => <Features data={data} {...props} />}
  />
);
