import styled from "styled-components";

import { sizes } from "../base_components/screenSizes";
import { lightBlue, darkBlue, grey } from "../base_components/colors";
import { Col, Row } from "../base_components/layout";
import { SPh1, SPsh1 } from "../base_components/typography";


export const TitleStyle = styled(SPh1)`
  color: ${darkBlue};
`;

export const DetailStyle = styled(SPsh1)`
  color: ${grey};
`;


export const WhatIsImg = styled.img`
  width: 100%;
`;

export const WhatIsCol = styled(Col)`
`;

export const WhatIsRow = styled(Row)`

  background: ${lightBlue};
  align-items: center;
  padding-top: 150px;
  padding-bottom: 150px;
  padding-left: 100px;
  padding-right: 100px;

  @media (max-width: ${sizes.large}px) {
    padding: 40px;
  }
`;

export const WhatIsDetailContainer = styled.div`
  margin-left: 60px;
  @media (max-width: ${sizes.large}px) {
    padding-left: 0;
    margin-top: 40px;
  }
`;
