import React from "react";
import { StaticQuery, graphql } from "gatsby";



import { WhatIsRow, WhatIsCol, WhatIsDetailContainer, WhatIsImg, TitleStyle, DetailStyle } from "./what_is_styles";

const WhatIs = ({ data }) => {
    const title = data.prismic.allHomepages.edges[0].node.what_is_smartypill[0].title[0].text;
    const content = data.prismic.allHomepages.edges[0].node.what_is_smartypill[0].content[0].text;
    const image = data.prismic.allHomepages.edges[0].node.what_is_smartypill[0].image.url;

    const imageAlt = data.prismic.allHomepages.edges[0].node.what_is_smartypill[0].image.alt;


    return (

        <WhatIsRow>
            <WhatIsCol size={1} breakSize={"large"}>
                <WhatIsImg src={image} alt={imageAlt} />
            </WhatIsCol>

            <WhatIsCol size={1} breakSize={"large"}>
                <WhatIsDetailContainer>
                    <TitleStyle>{title}</TitleStyle>
                    <DetailStyle>{content}</DetailStyle>
                </WhatIsDetailContainer>
            </WhatIsCol>
        </WhatIsRow>

    );
};


export default props => (
    <StaticQuery
        query={graphql`
        query WhatIsQuery {
            prismic {
                allHomepages {
                edges {
                    node {
                    what_is_smartypill {
                        content
                        title
                        image
                    }
                    }
                }
                pageInfo {
                    startCursor
                    endCursor
                }
                }
            }
        }
    `}
        render={data => <WhatIs data={data} {...props} />}
    />
)