import React from "react";

import { StaticQuery, graphql } from "gatsby";

import BackgroundPattern from "../base_components/background";
import Slider from "./slider/slider";

import { SliderContainer } from "./carousel_styles";

const CarouselComponent = props => {
  const { data } = props;
  const title =
    data.prismic.allHomepages.edges[0].node.testimonials_title[0].text;
  const testimonials = data.prismic.allHomepages.edges[0].node.testimonials;
  return (
    <SliderContainer>
      <BackgroundPattern
        strokeColor={"orange"}
        fillColor={"yellow"}
        left={0}
        bottom={10}
        zindex={-1}
      />
      <BackgroundPattern
        strokeColor={"orange"}
        fillColor={"yellow"}
        right={-50}
        top={10}
        zindex={-1}
      />

      <Slider slides={testimonials} title={title} autoPlay={5} />
    </SliderContainer>
  );
};

export default props => (
  <StaticQuery
    query={graphql`
      query TestimonialQuery {
        prismic {
          allHomepages {
            edges {
              node {
                testimonials_title
                testimonials {
                  testimonial_image
                  testimonial_location
                  testimonial_name
                  testimonial_quote
                }
              }
            }
          }
        }
      }
    `}
    render={data => <CarouselComponent data={data} {...props} />}
  />
);
