import styled from "styled-components";

import { orange, white } from "../../base_components/colors";
import { Col, Row } from "../../base_components/layout";

import { sizes } from "../../base_components/screenSizes";
import { SPsh1, SPbody1, SPh1 } from "../../base_components/typography";

export const TitleStyle = styled(SPh1)``;

export const NameStyle = styled(SPsh1)``;

export const QuoteStyle = styled(SPbody1)``;

export const SlideStyle2 = styled.div`
  height: auto;
  width: 100%;
`;

export const DotStyle = styled.div`
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: inline-block;
  border: 2px solid ${orange};
  background: ${props => (props.active ? `${orange}` : "white")};
  box-shadow: ${props =>
    props.active ? `inset 0 0 0 2px ${white}` : "initial"};
  cursor: pointer;

  :hover {
    box-shadow: inset 0 0 0 2px ${orange};
  }
`;

export const DetailsContainer = styled.div`
  flex-direction: column;
  padding-right: 20px;

  @media (max-width: ${sizes.medium}px) {
    padding: 0;
  }
`;

export const CarouselContainer = styled.div`
  width: 100%;
  min-height: 450px;
  display: flex;

  justify-content: center;
`;

export const PersonImg = styled.img`
  border-radius: 10px;
  width: 100%;
  max-width: 330px;
  height: auto;
  align-self: start;
`;

export const CarouselCol = styled(Col)`
  position: relative;

  @media (max-width: ${sizes.medium}px) {
    justify-content: center;
    display: flex;
  }
`;

export const CarouselColImg = styled(CarouselCol)`
  display: flex;
  justify-content: center;
`;

export const CarouselRow = styled(Row)`
  width: 60%;

  @media (max-width: ${sizes.small}px) {
    padding: 0;
    flex-direction: ${props =>
      props.isRightPhoto ? "column-reverse" : "initial"};
    width: 80%;
  }
`;
