import React from "react";

import {
  QuoteStyle,
  TitleStyle,
  NameStyle,
  SlideStyle2,
  CarouselContainer,
  CarouselRow,
  DetailsContainer,
  PersonImg,
  CarouselCol,
  CarouselColImg,
} from "./slide_styles";

const Slide = props => {
  const { content, title } = props;

  const quote = content.testimonial_quote[0].text;
  const name = content.testimonial_name[0].text;
  const imgSrc = content.testimonial_image.url;
  const imgAlt = content.testimonial_image.alt;

  return (
    <SlideStyle2>
      <CarouselContainer>
        <CarouselRow>
          <CarouselCol size={1} breakSize="medium">
            <DetailsContainer>
              <TitleStyle>{title}</TitleStyle>
              <QuoteStyle>"{quote}"</QuoteStyle>
              <NameStyle>{name}</NameStyle>
            </DetailsContainer>
          </CarouselCol>
          <CarouselColImg size={1} breakSize="medium">
            <PersonImg src={imgSrc} alt={imgAlt}/>
          </CarouselColImg>
        </CarouselRow>
      </CarouselContainer>
    </SlideStyle2>
  );
};

export default Slide;
