import React from "react";

import { SPh1 } from "../base_components/typography";

import {
  DetailContainer,
  HeroImg,
  HeroCol,
  HeroImgCol,
  HeroRow,
  HeroContainer,
} from "./hero_styles";

import BackgroundPattern from "../base_components/background";

const Hero = props => {
  const { title, heroImg, alt } = props;

  return (
    <HeroContainer>
      <BackgroundPattern
        strokeColor={"pillBackgroundBlue"}
        fillColor={"pillBackgroundBlue"}
        left={-100}
        top={-100}
        zindex={-1}
      />

      <BackgroundPattern
        strokeColor={"pillBackgroundBlue"}
        fillColor={"pillBackgroundBlue"}
        right={-50}
        bottom={-100}
        zindex={-1}
      />

      <HeroRow>
        <HeroCol size={2} breakSize="medium">
          <DetailContainer>
            <SPh1>{title}</SPh1>
          </DetailContainer>
        </HeroCol>

        <HeroImgCol size={3} breakSize="medium">
          <HeroImg src={heroImg} alt={alt}/>
        </HeroImgCol>
      </HeroRow>
    </HeroContainer>
  );
};

export default Hero;
